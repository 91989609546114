<template>
  <div class="order-info">
    <div class="createOrder">创建订单</div>

    <div class="createMenu" v-if="isLogin">
      <button
        type="button"
        class="btn btn-danger"
        @click="newOrder()"
        :disabled="mainData.code"
      >
        <i
          class="fa fa-pencil"
          aria-hidden="true"
          style="line-height: 15px; margin-right: 5px"
        ></i>
        新建订单
      </button>
      <button
        type="button"
        class="btn btn-success"
        @click="changeCount()"
        :disabled="!mainData.code"
      >
        <i
          class="fa fa-floppy-o"
          aria-hidden="true"
          style="line-height: 15px; margin-right: 5px"
        ></i>
        保存订单
      </button>
      <!-- <div>
            <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true" @click="newOrder()"></i>新建订单

            </div> -->
      <div class="table">
        <div class="table-main">
          <div class="form-group form-inline" style="margin: 20px 0 10px">
            <label for="companyName" style="margin-left: 10px">订单编号</label>
            <input
              :value="mainData.code"
              type="text"
              class="form-control"
              id="code"
              style="margin-left: 25px; width: 500px; background-color: white"
              disabled="disabled"
            />
          </div>

          <div class="form-group form-inline" style="margin: 10px 0 10px">
            <label for="companyName" style="margin-left: 10px">公司名称</label>
            <input
              :value="mainData.cust_name"
              type="text"
              class="form-control"
              id="companyName"
              style="margin-left: 25px; width: 500px; background-color: white"
              disabled="disabled"
            />
          </div>

          <div class="form-group form-inline" style="margin: 10px 0 10px">
            <label for="orderDate" style="margin-left: 10px">订单日期</label>
            <input
              v-model="orderDate"
              type="text"
              class="form-control"
              id="orderDate"
              style="margin-left: 25px; width: 500px; background-color: white"
              disabled="disabled"
            />
          </div>

          <div class="row form-inline" style="margin: 10px 0 10px 0">
            <div class="form-group" style="margin-left: 10px">
              <label for="shipDate">交货日期</label>
              <!-- <input
                    @change="changeCount()"
                    v-model="shipDate"
                    type="datetime"
                    class="form-control"
                    id="shipDate"
                    style="margin-left: 5px; width: 205px"
                  /> -->
              <el-date-picker
                v-model="shipDate"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                type="datetime"
                placeholder="选择交货日期"
                :picker-options="expireTimeOption"
                default-time="18:00:00"
                :disabled="!this.mainData.code"
              >
              </el-date-picker>
            </div>

            <div class="form-group" style="margin-left: 20px">
              <label for="yarn_date">来纱日期</label>
              <el-date-picker
                v-model="yarn_date"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                type="datetime"
                placeholder="选择来纱日期"
                :picker-options="expireTimeOption"
                default-time="18:00:00"
                :disabled="!this.mainData.code"
              >
              </el-date-picker>
            </div>
          </div>

          <div class="form-group form-inline" style="margin: 10px 0 10px">
            <label for="ref_order" style="margin-left: 10px">参考织法</label>
            <textarea
              v-model="ref_order"
              type="text"
              class="form-control"
              id="ref_order"
              style="
                margin-left: 25px;
                width: 500px;
                height: 50px;
                resize: none;
                background-color: white;
              "
              :disabled="!this.mainData.code"
            />
          </div>

          <div
            class="form-group form-inline"
            style="margin: 10px 0 0; paddind-bottom: 10px"
          >
            <label for="notes" style="margin-left: 10px"
              >备<span class="remark">注</span></label
            >
            <textarea
              v-model="notes"
              type="text"
              class="form-control form-note"
              id="notes"
              :disabled="!this.mainData.code"
              style="
                margin-left: 25px;
                width: 500px;
                height: 50px;
                resize: none;
                background-color: white;
              "
            />
          </div>
        </div>
      </div>
    </div>

    <template v-if="isLogin">
      <!-- 表格 -->
      <v-app id="inspire">
        <div class="childTable">
          <v-data-table
            :headers="headers"
            :items="desserts"
            sort-by="calories"
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog
                  v-model="dialog"
                  max-width="500px"
                  :disabled="!mainData.code"
                >
                  <!-- 增加数据按钮 -->
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#ff4d35"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i
                        class="fa fa-plus-circle"
                        aria-hidden="true"
                        style="margin-right: 5px"
                      ></i>
                      增加订单明细
                    </v-btn>
                  </template>

                  <!-- 编辑区域 -->
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.cust_po"
                              label="客单号"
                              @keyup="upCapital"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.cust_no"
                              label="客布号"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.name"
                              label="产品名称"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.style_code"
                              label="款号"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.color"
                              label="颜色"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.sizes"
                              label="尺寸"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              v-model="editedItem.qty2"
                              label="数量"
                              type="number"
                              @keydown.native="upNumber"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-select
                              v-model="editedItem.unit2"
                              :items="numOptions"
                            ></v-select>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              v-model="editedItem.qty"
                              label="重量"
                              type="number"
                              @keydown.native="inputLimit"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-select
                              v-model="editedItem.unit"
                              :items="options"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">
                        取消
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="save()">
                        保存
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- 删除确认区域 -->
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline"
                      >确定要删除此数据吗?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >取消</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >确定</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <!-- 编辑数据 -->
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                title="编辑"
                color="#007bff"
              >
                mdi-pencil
              </v-icon>
              <!-- 删除数据 -->
              <v-icon
                small
                @click="deleteItem(item)"
                title="删除"
                color="#ff5252"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </v-app>
    </template>

    <div class="login-text" v-else>
      <button type="button" class="btn btn-success" @click="goPage('Login')">
        登录
      </button>
    </div>
  </div>
</template>

<script>
import "../../assets/css/orderinfo.less";
import "../../assets/css/adminhome.less";
import "../../assets/css/dashboard.less";
export default {
  data() {
    return {
      // 登录名
      username: "",
      cust_id: "",
      cust_name: "",
      // 检测是否登录
      isLogin: false,
      // 主表数据
      mainData: {},
      // 是否展示增加数据区域
      dialog: false,
      // 是否展示删除数据区域
      dialogDelete: false,
      // 数量单位选择
      numOptions: ["条", "打"],
      //重量单位选择
      options: ["KG", "LBS"],
      // 订单日期
      orderDate: "",
      // 交货日期
      shipDate: "",
      // 来纱日期
      yarn_date: "",
      // 参考织法
      ref_order: "",
      // 备注
      notes: "",
      url: "http://beian.miit.gov.cn/",
      key: "",
      // 页面展示标题
      headers: [
        { text: "客单号", value: "cust_po", sortable: false },
        { text: "客布号", value: "cust_no", sortable: false },
        { text: "产品名称", value: "name", sortable: false },
        { text: "款号", value: "style_code", sortable: false },
        { text: "颜色", value: "color", sortable: false },
        { text: "尺寸", value: "sizes", sortable: false },
        { text: "数量", value: "qty2", sortable: false },
        { text: "数量单位", value: "unit2", sortable: false },
        { text: "重量", value: "qty", sortable: false },
        { text: "重量单位", value: "unit", sortable: false },
        { text: "操作", value: "actions", sortable: false },
      ],
      // 新增数据内容
      desserts: [],
      editedIndex: -1,
      // 当前在对话框中编辑的数据
      editedItem: {
        id: "",
        fid: "",
        cust_po: "",
        cust_no: "",
        name: "",
        style_code: "",
        color: "",
        sizes: "",
        qty2: "",
        unit2: "",
        qty: "0",
        unit: "",
        record_by: "",
        edit_by: "",
        status: "",
      },
      // 默认数据，用于新增对话框内容
      defaultItem: {
        id: "",
        fid: "",
        cust_po: "",
        cust_no: "",
        name: "",
        style_code: "",
        color: "",
        sizes: "",
        qty2: "",
        unit2: "",
        qty: "0",
        unit: "",
        record_by: "",
        edit_by: "",
        status: "",
      },
      // 细表id
      dataRow: " ",
      // 设置开始时间
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "增加数据" : "修改数据";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getUserInfo();
    if (sessionStorage.getItem("user")) {
      this.isLogin = true;
    }
  },

  mounted() {
    this.orderDate = this.todayDate();
    // 置顶
    window.scrollTo(0, 0);
  },

  methods: {
    goBean() {
      var r = confirm("您将要访问：http://beian.miit.gov.cn/，是否继续？");
      if (r == true) {
        window.location.href = this.url;
      }
    },

    // 跳转页面
    goPage(name) {
      this.$router.push({ name });
    },

    // 获取用户信息
    getUserInfo() {
      this.username = sessionStorage.getItem("user");
      this.cust_id = sessionStorage.getItem("cust_id");
      this.cust_name = sessionStorage.getItem("cust_name");
    },

    // 退出登录
    logout() {
      if (this.cust_id == "261") {
        this.$router.push({ name: "CompanyLogin" });
      } else {
        this.$router.push({ name: "Login" });
      }
      sessionStorage.clear();
    },

    // 默认日期为今天
    todayDate() {
      var date = new Date();
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      var d = date.getDate();
      var h = date.getHours();
      var min = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      d = d < 10 ? "0" + d : d;
      h = h < 10 ? "0" + h : h;
      min = min < 10 ? "0" + min : min;
      return y + "-" + m + "-" + d + " " + h + ":" + min;
    },

    // 客单号只输入大写字母
    upCapital() {
      this.editedItem.cust_po = this.editedItem.cust_po.replace(
        /[^A-Za-z]/g,
        ""
      );
      this.editedItem.cust_po = this.editedItem.cust_po.toUpperCase();
    },

    // 控制数量输入
    upNumber(e) {
      let key = e.key;
      if (
        key === "e" ||
        key === "E" ||
        key === "+" ||
        key === "-" ||
        key === "."
      ) {
        e.returnValue = false;
        return false;
      }
      return true;
    },

    // 控制重量输入
    inputLimit(e) {
      let key = e.key;
      if (key === "e" || key === "E" || key === "+" || key === "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },

    // 触发编辑按钮
    editItem(item) {
      // 找到修改数据的索引值
      this.editedIndex = this.desserts.indexOf(item);
      // 将未修改的值存进editedItem
      this.editedItem = Object.assign({}, item);
      // 确认修改
      this.dialog = true;
    },

    // 触发删除按钮
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    // 删除确认
    deleteItemConfirm() {
      this.editedItem.fid = this.mainData.id;
      this.editedItem.record_by = this.username;
      this.editedItem.edit_by = this.username;
      this.editedItem.status = "delete";
      var subList = {
        ctype: "orderD",
      };
      var key2 = "Data";
      subList[key2] = this.editedItem;
      var subChildList = JSON.stringify(subList);

      this.axios
        .post("http://wx.hengfeng-zl.cn/dingtalk/weborders.ashx", subChildList)
        .then((res) => {
          if ((res.data.row = 1)) {
            this.$message({
              showClose: true,
              message: "订单明细删除成功",
              type: "success",
            });
          }
        })
        .catch((err) => {});

      // 删除相关的项
      this.desserts.splice(this.editedIndex, 1);

      this.closeDelete();
    },

    // 增加/修改数据-取消
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        // 初始化editedItem数据
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    // 删除数据-取消
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        // 初始化editedItem数据，清空
        this.editedItem = Object.assign({}, this.defaultItem);

        this.editedIndex = -1;
      });
    },

    // 增加/修改数据-保存
    save() {
      // 修改数据
      if (this.editedIndex > -1) {
        if (
          !this.editedItem.cust_po ||
          !this.editedItem.cust_no ||
          !this.editedItem.name ||
          !this.editedItem.qty2 ||
          !this.editedItem.unit2 ||
          !this.editedItem.sizes
        ) {
          return false;
        } else {
          Object.assign(this.desserts[this.editedIndex], this.editedItem);
          this.editedItem.fid = this.mainData.id;
          this.editedItem.record_by = this.username;
          this.editedItem.edit_by = this.username;
          this.editedItem.status = "update";
          var subList = {
            ctype: "orderD",
          };
          var key2 = "Data";
          subList[key2] = this.editedItem;
          var subChildList = JSON.stringify(subList);

          this.axios
            .post(
              "http://wx.hengfeng-zl.cn/dingtalk/weborders.ashx",
              subChildList
            )
            .then((res) => {
              if ((res.data.row = 1)) {
                this.$message({
                  showClose: true,
                  message: "订单明细修改成功",
                  type: "success",
                });
              }
            })
            .catch((err) => {});
        }
      } else {
        //增加数据
        if (
          !this.editedItem.cust_po ||
          !this.editedItem.cust_no ||
          !this.editedItem.name ||
          !this.editedItem.sizes ||
          !this.editedItem.qty2 ||
          !this.editedItem.unit2
        ) {
          return false;
        } else {
          this.editedItem.id = this.desserts.length + 1;
          this.editedItem.fid = this.mainData.id;
          this.editedItem.record_by = this.username;
          this.editedItem.status = "insert";
          var subList = {
            ctype: "orderD",
          };
          var key2 = "Data";
          subList[key2] = this.editedItem;
          var subChildList = JSON.stringify(subList);

          this.axios
            .post(
              "http://wx.hengfeng-zl.cn/dingtalk/weborders.ashx",
              subChildList
            )
            .then((res) => {
              if ((res.data.row = 1)) {
                this.$message({
                  showClose: true,
                  message: "订单明细增加成功",
                  type: "success",
                });
              }
              this.desserts.push(subList[key2]);

              this.dataRow = res.data.row;

              var saveIndex = this.desserts.indexOf(subList[key2]);

              this.desserts[saveIndex].id = this.dataRow;
            })
            .catch((err) => {});
        }
      }
      this.close();
    },

    // 新增订单
    newOrder() {
      this.axios({
        methods: "GET",
        url: "http://wx.hengfeng-zl.cn/dingtalk/weborders.ashx",
        params: {
          ctype: "getOrderCode",
          cust_id: this.cust_id,
          record_by: this.username,
        },
      })
        .then((res) => {
          this.mainData = res.data.data[0];
        })
        .catch((err) => {});
    },

    //提交主表单
    changeCount() {
      if (!this.mainData.code) {
        return false;
      } else {
        var mainList = {
          ctype: "orderM",
        };
        var newMainData = {
          id: this.mainData.id,
          code: this.mainData.code,
          cust_id: this.cust_id,
          cust_name: this.mainData.cust_name,
          cust_shipDate: this.shipDate,
          yarn_date: this.yarn_date,
          ref_order: this.ref_order,
          notes: this.notes,
          record_by: this.username,
          status: "update",
        };
        var key1 = "Data";
        mainList[key1] = newMainData;

        var subMainList = JSON.stringify(mainList);

        this.axios
          .post("http://wx.hengfeng-zl.cn/dingtalk/weborders.ashx", subMainList)
          .then((res) => {
            if ((res.data.row = 1)) {
              this.$message({
                showClose: true,
                message: "订单保存成功",
                type: "success",
              });
            }
          })
          .catch((err) => {});
      }
    },

    handleOpen(key, keyPath) {},

    handleClose(key, keyPath) {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 0.95rem;
  color: black;
  font-weight: 400;
  text-align: center !important;
  padding: 0px;
}
::v-deep .col-12 {
  height: 70px;
}

::v-deep .col-2 {
  padding-right: 15px;
  padding-left: 0px;
}

::v-deep .v-application .headline,
.v-card__title .headline {
  font-size: 1.2rem !important;
}

::v-deep .v-dialog > .v-card > .v-card__actions {
  padding: 0 16px 5px;
}

::v-deep .v-dialog > .v-card > .v-card__title {
  padding: 16px 24px 0px;
}

::v-deep .v-dialog > .v-card > .v-card__text {
  padding: 0 24px 0 24px;
}

::v-deep .v-btn--contained,
.v-application .elevation-1 {
  box-shadow: 0 0 0 0 white !important;
}

::v-deep .v-toolbar__content,
.v-toolbar__extension {
  margin: 0px 10px 0px;
  padding: 0px 20px 0px;
  background-color: #f6f7fb;
}

::v-deep .v-data-table__wrapper {
  margin: 0px 10px 0px;
  padding: 0px 20px 0px;
  background-color: #f6f7fb;
  border-radius: 5px;
}
::v-deep .v-data-table > .v-data-table__wrapper > table {
  border: 1px solid #ccc;
  margin-bottom: 20px;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  text-align: center !important;
}
::v-deep .v-expansion-panel-content:last-child {
  margin-bottom: 50px !important;
}
::v-deep .v-application {
  background-color: #f3f3f3;
}
::v-deep .v-application--wrap {
  margin: 0px 8px 0px;
}
::v-deep .v-btn.v-size--default {
  width: 155px;
  font-size: 1rem;
  line-height: 30px;
  outline: #ff9a35;
  border-radius: 15px;
  margin: 0 !important;
}

::v-deep .v-data-table-header {
  background-color: #ececec;
}
::v-deep .v-btn--contained,
.v-application .elevation-1 {
  border-radius: 5px;
  padding-bottom: 10px !important;
}

::v-deep .theme--light.v-toolbar.v-sheet {
  margin-top: 20px;
}

::v-deep .v-btn__content {
  margin-top: 6px;
}

::v-deep .v-application .blue--text.text--darken-1 {
  color: #d3002f !important;
  caret-color: #d3002f !important;
}
::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
}

::v-deep .el-menu-item:focus,
.el-menu-item:hover,
.el-submenu__title:hover,
.el-submenu__title:focus {
  background-color: #fef2f0;
  color: #f5533d;
}

::v-deep .el-menu-item.is-active {
  color: #f5533d;
  background-color: #fef2f0;
}

::v-deep .el-submenu__title i:not(.el-icon-arrow-down) {
  margin-right: 22px !important;
  color: #f5533d !important;
}

::v-deep .el-submenu__title {
  font-size: 16px;
}

::v-deep .el-submenu .el-menu-item {
  font-size: 16px;
  padding: 0 0 0 60px !important;
}

::v-deep .el-icon-time:before {
  display: none;
}

::v-deep .el-input--prefix .el-input__inner {
  padding: 0 0 0 10px;
  font-size: 1rem;
  width: 195px;
  height: 32px;
  margin-left: 25px;
  border: 1px solid #ced4da;
  background-color: white;
  color: #495057;
}

::v-deep .el-picker-panel .el-date-picker .el-popper .has-time {
  height: 200px;
}

::v-deep .el-icon-circle-close {
  margin-right: 0px;
  line-height: 32px;
}

::v-deep .el-input__inner[disabled],
::v-deep .el-input__inner:disabled {
  cursor: auto !important;
}

::v-deep .el-input__inner:focus {
  border: 3px solid #bfdeff !important;
}
</style>